@import "src/client_customizations/scss/client_master.scss"; 

.EventCard {
  width: 32%;
  margin: 1%;
  background-color: $EventCard-background-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;

  .event-image-caontainer {
    position: relative;
    .event-image {
      width: 100%;
    }
    .logo-image {
      position: absolute;
      top: $EventCard-logo-image-position-top;
      left: $EventCard-logo-image-position-left;
    }
  }

  .EventCardTop {
    padding: 1rem;
  }

  .hosted-by {
    font-size: 12px;
    font-weight: 500;;
  }

  .date-row, .time-row, .location-row, .address-container {
    flex-wrap: nowrap;

  }
  .event-link {
    .btn {
      border-radius: $EventCard-btn-border-radius;
      background-color: $EventCard-btn-background-color;
      color: $EventCard-btn-color;
      border: $EventCard-btn-border;
      width: 100%;
      &:hover {
        background-color: $EventCard-btn-hover-background-color;
        border: $EventCard-btn-hover-border;
        color: $EventCard-btn-hover-color;
      }
    }
  }
  .date-time-row, .location-row, .hosted-by {
    padding-bottom: 10px;
  }
  .address-container {
    padding-bottom: 20px;
  }
}
