@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */
.Header {
	background-color: $Header-background-color;
	// border-bottom: $Header-border-bottom;
	a {
		color:$Header-link-color;
	}
	.bottomHeader {
		background-color: black;
		display: flex;
		justify-content: center;
		color: white;
		margin: 0;
		padding: 5px;
	}
	.mobile-top-header {
		margin: 0;
	}
	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;
			img {
				width: 160px;
			}
		}
		.navbar-collapse {
			a.active {
				span {
					// color: $Header-navbar-link-color;
					border-bottom: 2px solid #33A9C1;
					text-decoration: none;
				}
			}

      .navbar-nav {
        align-items: center;
      }

      .dropdown-item {
        border-radius: 0;
      }
		}

    .dropdown-menu {
      top: 50px;
      border-radius: 3px;

      .dropdown-item {
        font-size: 13px;

		  &.item {
			  color: white;
		  }
		  &.off {
			  margin-left: 4px;
		  }
        &.active,
        &:active {
			 border-left: 3px solid white;
          background-color: transparent;
          color: $Header-navbar-link-color;
        }
      }
    }
	}
	.nav-item {
		margin: 5px;
	}
	.input-zipcode {
		justify-content: flex-end;
		background-color: white;
		margin:	inherit;
		.input-group-text {
			background-color: white;
			border: none;
		}
		.btn {
			color: #005F83;
		}
	}
	li {
		display: flex;
	}
	.dropdown-menu {
		background-color: #232425;
		min-width: 0;
		.dropdown-item:hover{
			background-color: #0073B1;
			color: #F9F9F9
		}
		.dropdown-item {
			white-space: normal;
		}
	}
	span {
		font-size: 16px;
	}
	.form-inline {
		flex-flow: row;
	}
}
.skip-to-content-link {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;
}
a.skip-to-content-link {
	&:active {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:focus {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:hover {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
}
.navbar-toggler {
	border-color: $Header-navbar-toggler-border-color;
	color:$Header-navbar-toggler-color;
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(241,92,42, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}
@media (min-width: 992px) {
	.Header {
		.input-zipcode {
			padding: .5rem;
		}
		.navbar {
			padding: .75rem;
			.navbar-collapse {
				a {
					padding: 15px;
					border-radius: 4px;
					font-weight: 700;
					letter-spacing: 0.6px;
					text-decoration: none;
				}
				a.active {
					font-weight: bold;
				}
			}
		}
	}
}

@media (max-width: 766px) {
	.Header {
		#update-zipcode-button {
			margin-left: 8px;
		}
		.input-group-text {
			padding: 3px 3px;
		}
	}
}
