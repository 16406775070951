@import "src/client_customizations/scss/client_master.scss";

.charging-perks {
 
    a {
        font-weight: bold;
    }

    li {
        margin-left: 3rem;
    }

    ul.inner-list {
        list-style-image: url('../../assets/images/hollow-bullet.svg');

        li.inner-bullet {
            margin-left: 0;
        }
    }

    .charging-perks-iframe {
        text-align: center;
        margin-top: 1rem;
    }

    iframe {
        width: 100%;
    }
}