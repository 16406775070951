@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: $GenericFooter-background-color;
	padding-top: 20px;
	color: $GenericFooter-color;
	.h3 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
	}
	a {
		transition: 0.3s all ease;
		-webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		font-weight: 700;
		&:hover {
			color: $GenericFooter-links-hover-color;
			padding-left: 5px;
			// text-decoration: underline;
		}
	}
	.p {
		color: $GenericFooter-links-color;
	}
	.local-links {
		display: flex;
		justify-content: space-between;
		.left-info {
			margin-right: 30px;
		}
		margin-left: 30px;
	}
	.social-media-row {
		padding-bottom: 1.5rem;
		a {
			padding-right: 15px;
		}
	}
	span {
		color: black;
	}
	.more-info {
		margin-left: 20px;
		span {
			font-size: 13.5px;
		}
	}
	.aps-link {
		color: #007BBD;
		font-weight: 700;
	}
	.line {
		margin-top: 3px;
	}
}

.disclaimer-container {
       div {
              margin-top:25px;
              padding:0 !important;
			  align-items: left;
              background-color:transparent !important;

              img {
                     display:block;
                     max-width: 100%;
                     margin:0 auto 20px;
              }

              p {
                     text-align: left;
                     font-size:12px;
                     line-height: 1.5em;
              }
       }
}