@import "src/client_customizations/scss/client_master.scss";

.link-card-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	max-width: 500px;
	margin: auto;
	
}
.link-card {
	display: flex;
	text-align: center;
	// flex-shrink: 1;
	flex-basis: 80px;
	cursor: pointer;
	img {
		width: 50px;
		margin: 30px 0px;
	}
	p {
		margin-top: 0.5rem;
		margin-bottom: 0;
	}
	&:hover {
		p {
			text-decoration: none;
		}
	}
	.evc-card {
		display: flex;
		align-items: center;
		border: none;
		background-color: $LinkCard-evc-card-background-color;
		padding: 0.5rem;
		border-radius: 4px;
		margin-bottom: 20px;
		height: 100%;
		p {
			font-size: 0.7rem;
		}
		&:hover {
			background-color: white;
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
		}
		.btn-homepage {
			color: #0074B3;
			font-weight: 700;
		}
		
	}
}
@media (max-width: 576px) {
	.link-card-container {
		flex-direction: column;
	}
}
@media (min-width: 576px) {
	.link-card-container {
		max-width: 1200px;
	}
	.link-card {
		flex-basis: 120px;
		img {
			margin-top: 30px;
			width: 100px;
		}
		.evc-card {
			
			p {
				font-size: 16px;
			}
			padding: 1rem;
		}
	}
}
@media (min-width: 768px) {
	.link-card {
		flex-basis: 215px;
	}
}
@media (max-width: 425px) {
	.link-card {
		.evc-card {
			margin: 0 auto;
		}
	}
}