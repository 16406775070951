@import "src/client_customizations/scss/client_master.scss";

.IncentiveCard {
	margin-right: 1%;
	margin-bottom: 1%;
	border-radius: $IncentiveCard-card-border-radius;
	border:	none;
	border-top: 8px solid #0081C6;
	box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.14);
	background-color: white;
	width: 255px;
	height: 369px;
	
}
.IncentiveCardBody {
	padding: 0rem .5rem .5rem .5rem;
	text-align: center;
	color: $IncentiveCard-card-body-color;
	border-radius: 0;
	.card-title {

		font-size: 32px;
		font-weight: 400;
		margin-top: 1rem;
		color: $IncentiveCard-card-title-color;
	}
	img {
		margin-top: -12px;
		margin-bottom: -16px;
		width: 60px;
	}
}
.IncentiveCardTop {
	padding: 1rem;
	text-align: center;
	font-size: 14px;
	color: $IncentiveCard-card-body-color;
	letter-spacing: .75px;
	text-transform: uppercase;
	border-radius: 0;
	font-weight: 400;
	.card-title {
		margin-top: 1rem;
		color: $IncentiveCard-card-title-color;
	}
	span {
		font-weight: 700;
	}

}
hr {
	border-top: 2px solid #DADADA;
}

.IncentiveCardBottom {
	padding: 1rem;
	color: #000000;
	font-size: 14px;
	font-weight: 400;

}
@media (min-width: 768px) {
	.IncentiveCardBody {
		padding: 0rem .5rem .5rem .5rem;
	}
	.IncentiveCardBottom {
		padding: 1.5rem;
	}
}
.displayNone {
	display: none !important;
}
.showBtn {
	padding: 2px 0;
	border: none;
	background-color: transparent;

	color: #007BBD;
}
.showAllCard {
	min-height: 369px !important;
	height: auto !important;
	max-height: 1000px !important;

	.description {
		min-height: 88px !important;
		height: auto !important;
		max-height: 1000px !important;
	}
}