

@import "src/client_customizations/scss/client_master.scss"; 

.ChargingAvailabilityWizard {
  display: flex;
  .input-well {
    background-color: transparent;
    padding: 20px;
    border-radius: 0;
    border: none;
  }

  .text-charger-availability {
    font-weight: bold;
    display: flex;
    color: #ffffff;
    font-weight: 700;
    font-size: 24px;
    justify-content: center;
    margin-top: 30px;
  }
  .home-box {
	  width: 160px;
    height: 185px;
    margin: 10px;
    background-color: #0081C6;
  }
  .home-title {
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 12px;
    color: #ffffff;
    margin: 20px;
  }
  .result {
    display: flex;
  }
  .btn-charging {
    width: 160px;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 0;
    border: 1px solid #0081C6;
    color: #0081C6;
    font-weight: 600;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
  }
}