@import "src/client_customizations/scss/client_master.scss";

.EVsSort {
	margin-bottom: 15px;
	label {
		font-weight: $EVsSort-label-font-weight;
		font-size: $EVsSort-label-font-size;
		color: $EVsSort-label-color;
		letter-spacing:$EVsSort-label-letter-spacing;
		line-height: $EVsSort-label-line-height;
	}
	select {
		background: $EVsSort-select-background;
		border: $EVsSort-select-border;
		border-radius:$EVsSort-select-border-radius;
		font-size: $EVsSort-select-font-size;
		color: $EVsSort-select-color;
		letter-spacing: $EVsSort-select-letter-spacing;
	}
}

.ev-toggle {
	@media (min-width: 576px) {
		width: 60%;
	}
	padding:0;
	display: flex;
	align-items: center;
	background-color:#fff;
	margin: 50px 0 35px;
   margin-left: auto;
   margin-right: auto;
	box-shadow: 0px 20px 41px rgba(0, 0, 0, 0.1);

	a {
		@media (min-width: 576px) {
			width:50%;
		}
		
		text-align: center;
		color:#2A2A2A;
		text-transform: uppercase;
		background-color:#fff;
		padding: 20px 0px;
		font-size:16px;
		font-weight: 600;
		transition: all .2s ease-out;
		
		&.active {
			background: #007BBD;
			color:#fff;
		}

		&:hover {
			text-decoration: none;
			// background-color:#E3001C;
			// color:#fff;
		}
	}
}

@media (min-width: 576px) {
	.EVsSort {
		float: right;
	}
}
