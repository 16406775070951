$color_1: #07679b;
$color_2: #666666;
$color_3: #DBDBDB;
$color_4: #fff;
$color_5: #005F83;
$color_6: #0081C6;
$font_family_1: "Gotham Book", "Open Sans" sans-serif !important;
$background_color_1: #fff;
$background_color_2: white;
$background_color_3: #f2f2f2;
$background_color_4: #0081C6;
$background_color_5: #f3f4f4;
$background_color_6: #0071b7;
$background_color_7: #EE1C2E;
$background_color_8: #F4F4F4;
$border_color_1: transparent;
$border_bottom_color_1: rgba(0, 0, 0, 0.25);
$border_bottom_color_2: #fff;

@font-face {
    font-family: 'Gotham Book';
    src: url("../../../client_customizations/assets/Gotham\ Book.otf") format("opentype");
}
@font-face {
    font-family: 'Gotham Bold';
    src: url("../../../client_customizations/assets/Gotham\ Bold\ Regular.ttf") format("opentype");
}

body {
	background-color: $background_color_1;
	font-family: "Gotham Book";
	font-size: 13px;
}
h1 {
	font-size: 42px;
}
.h1 {
	font-size: 42px;
}
h2 {
	font-size: 2rem;
}
.h2 {
	font-size: 18px;
}
.modal-title {
	font-size: 18px;
}
h3 {
	font-size: 16px;
}
.h3 {
	font-size: 16px;
}
h4 {
	font-size: 12px;
}
.h4 {
	font-size: 12px;
}
h5 {
	font-size: 10px;
}
.h5 {
	font-size: 10px;
}
.lead {
	font-size: 24px;
}
a {
	color: $color_1;
}
table {
	table-layout:fixed;
	tbody {
		th {
			font-weight: normal;
		}
		tr {
			th {
				&:first-child {
					text-align: left;
				}
			}
		}
	}
	tfoot {
		tr {
			th {
				&:first-child {
					text-align: left;
				}
			}
		}
	}
}
table.table-bordered {
	tfoot {
		td {
			font-weight: bold;
			border-top: 2px solid #dee2e6;
		}
		th {
			font-weight: bold;
			border-top: 2px solid #dee2e6;
		}
	}
	th {
		word-break: break-word;
	}
}
button.btn-sm {
	font-size: 80%;
}
.input-well {
	.button.btn-sm {
		font-size: 80%;
	}
	background-color: $background_color_2;
	border-radius: 5px;
	box-shadow: 0 20px 41px 0 rgba(0, 0, 0, 0.1);
	padding: 17px;
	.form-group {
		margin-bottom: 1.5rem;

		background-color: white;
		p {
			margin-bottom: 1rem;
		}
	}
	form {
		.form-group {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	label {
		font-weight: 700;
		font-size: 12px;
		color: $color_2;
		text-transform: uppercase;
	}
	.label-style {
		font-weight: 700;
		font-size: 12px;
		color: $color_2;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.btn {
		display: inline-block;
		border: 1px solid #dbdbdb;
		font-weight: 400;
		font-size: 14px;
		background-color: white;
		color: #007BBD;
		border-radius: 1px;
		height: 40px;
	}
	.btn.active {
		background-color: #007BBD;
		color: white;
		border: 1px solid #5ca4d1;
	}
	.btn-block-container {
		.filter-label{
			display: flex;
			align-items: center;
			width: 100%;
    		height: 40px;
			position: relative;
			.name{
				font-size: 14px;
			}
			.switch{
				position: absolute;
				right: 0;
			}
		}
		.btn {
			display: block;
			width: 100%;
			margin-bottom: 6px;
			text-align: left;
			.badge {
				background-color: $background_color_2;
				border: 1px solid #dbdbdb;
				margin-right: 5px;
				img {
					width: 20px;

					padding-right: 17px;
				}
			}
			.filter-label {
	
				display: flex;
				align-items: center;
				width: 100%;
				height: 40px;

				.name {
					display: inline-flex;
					white-space: nowrap;
				}
				.switch {
					position: absolute;
    				left: 70%;
					.react-switch-bg {
						width: 50px !important;
					}
				}
			}
			.filter-switch {
				display: flex;
			}
			.first {
				margin-bottom: 10px;
			}
		}
	}
	.btn-grid-container {
		display: flex;
		flex-wrap: wrap;
		button {
			width: 48%;
			margin: 2%;
			&:nth-of-type(2n) {
				margin-right: 0;
			}
			&:nth-of-type(2n +1) {
				margin-left: 0;
					}
		}
	}
	.btn-ae {
		padding: 10px 40px;
		border-radius: 0;
		border: 1px solid #0081C6;
		color: $color_5;
		font-weight: 600;
		background-color: $background_color_1;
	}
}
.btn-default {
	border: 1px solid #ced4da;
}
strong {
	font-weight: 500;
}
hr {
	border-top: 2px solid rgba(0, 0, 0, 0.8);
	margin: 0;
	padding: 0;
}
.arrow-up {
	top: calc((1rem - 1px) * -1);
	position: absolute;
	display: block;
	width: 1rem;
	height: 1rem;
	margin: 0 0.3rem;
	&:before {
		position: absolute;
		display: block;
		content: "";
		border-color: $border_color_1;
		border-style: solid;
		top: 0;
		border-width: 0 1rem 1rem 1rem;
		border-bottom-color: $border_bottom_color_1;
	}
	&:after {
		position: absolute;
		display: block;
		content: "";
		border-color: $border_color_1;
		border-style: solid;
		top: 1px;
		border-width: 0 1rem 1rem 1rem;
		border-bottom-color: $border_bottom_color_2;
	}
}
.grey {
	background-color: $background_color_5 !important;
}
.white {
	background-color: $background_color_1 !important;
}

.bottom-thick-margin {
	border-bottom: 2px solid #000000;
}
.color-primary {
	color: $color_6;
}
.btn-ae {
	padding: 10px 40px;
	border-radius: 0;
	border: 1px solid #0081C6;
	color: $color_5;
	font-weight: 600;
	background-color: $background_color_1;
	&:hover {
		border-radius: 0;
		color: $color_4;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_6;
	}
}
.btn-aps {
	padding: 10px 40px;
	border-radius: 0;
	color: $color_4;
	font-weight: 600;
	background-color: #007BBD;
	border: none;

	&:hover {
		border-radius: 0;
		color: $color_6;
		font-weight: 600;
		background-color: $background_color_1;
		border: 1px solid $color_6;
	}
}
.PageLayout {
	a.btn-ae {
		&:hover {
			border-radius: 0;
			color: $color_4;
			font-weight: 600;
			text-decoration: none;
			background-color: $background_color_6;
		}
	}
}
a.btn-ae {
	&:hover {
		border-radius: 0;
		color: $color_4;
		font-weight: 600;
		text-decoration: none;
		background-color: $background_color_6;
	}
}

// .select-ae {
// 	-webkit-appearance: none;
// 	-moz-appearance: none;
// 	-ms-appearance: none;
// 	appearance: none;
// 	background-repeat: no-repeat;
// 	background-position: right 15px top 11px, right 10px top 1em;
// 	background-size: 15px 15px, 15px 15px;
// 	background-image: url(../../assets/images/icons/Link-arrow.png);
// }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ CSS */
	.select-ae {
		background-size: 0;
	}
 }
 .btn-arrow {
	background: none;
	border: none;
	color: #205694;
	font-weight: 600;
	font-size: 12px;
}
 #vehicle-sort-dropdown {
	-webkit-appearance: none;
   -moz-appearance: none;
	background: url(../../assets/images/Triangle.svg);
	background-position: 95%;
	background-size: .5em .5em;
   background-repeat: no-repeat;
	background-color: $background_color_1;

}

.selected-subText {
	display: flex;
	margin: 10px;
	font-weight: 400;
	color: #666666;
	font-size: 14px;
}
.shadowed {
    box-shadow: 0 20px 41px 0 rgba(0, 0, 0, 0.1);
}

.bold {	
	font-weight: 700 !important;
}
.light {
	font-weight: 400 !important;
}
.small-centered {
	margin-left: auto;
	margin-right: auto;
	width: 60%;
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 800px;
		margin: 1.75rem auto;
	}
	.modal-dialog {
		max-width: 800px;
		margin: 1.75rem auto;
	}
	
}

.incentive-label-select {
	font-size: 12px;
	text-transform: uppercase;
}
.container-fluid-tight {
	padding: 2rem 8rem;
 }

 @media (max-width: 576px) {
	 .container-fluid-tight {
		 padding: 2rem 2rem !important;
	 }
 }
 
 .grey {
	 background-color: #f3f3f3 !important;
 }
 .bold {
	 font-family: "Gotham Bold";
 }

.title-head {
	font-weight: 700;
	font-family: "Gotham Bold";
	font-size: 32px;
}
.title-sub {
	display: flex;
	justify-content: center;
	font-weight: 400;
	font-size: 16px;
}
.btn-maps {
	padding: 10px 40px;
	border-radius: 0;
	color: $color_4;
	font-weight: 600;
	background-color: #007BBD;
	border: none;
}



@media (min-width: 768px) { 
	.map-options {
	display: flex;
	margin-left: 15px;
	.zip-options {
	display: flex;
	flex-direction: column;
	}
.power-options {
	margin-left: 50px;
	}
}
	#select-vehicle-power-filter, #travel-radius-map-address-input {
		font-weight: 700;
		color: #666666;
		width: 218px;
	}
	.btn-maps {
		margin-top: 26px;
		margin-left: 30px;
		width: 240px;
		
	}
	@media (max-width: 576px) {
		.btn-maps {
		margin-top: auto;
		margin-left: 0px !important;
		width: 240px;
		
		}
	}
}
.input-working-zipcode label {
	font-family: "Gotham Bold";
  text-transform: capitalize;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  color: #666666;
  margin-bottom: 8px;
}
.evHead {
	display: flex;
	justify-content: center;
	font-family: "Gotham Bold";
	font-size: 42px;
}
.compare-select-title {
	font-size: 12px;
	color: #2A2A2A;
	text-transform: uppercase;

}
.select-custom {

	.css-13cymwt-control,
	.css-t3ipsp-control {
		min-height: 40px;
		height: 59px;
		font-size: '14px';
		color: '#666666';
	}

	.css-1nmdiq5-menu {
		margin-top: 0px;
	}
}
.graph-title {
	font-weight: 600;
	font-size: 16px;
	margin: 10px;
}

.bottom-margin {
	margin-bottom: 40px !important;
}
.top-margin {
	margin-top: 40px !important;
}
.MatchScoreOptions {
	margin-top: 10px !important;
}
.form-group {
	background-color: white;
}
.show-hide-btn-arrow {
		width: 100%;
		border-radius: 0;
		font-weight: 600;
		padding: .75rem 2rem;
		color: #2C61AE !important;
		background-color: $background_color_5 !important;
		border: 1px solid $color_3 !important;
		background: url(../../assets/images/blue-triangle.svg);
    	background-position: 95%;
		background-size: .75em .75em;
    	background-repeat: no-repeat;
		
}
.graph-sub-title {
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	color: #2b2b2b;

}
.compare-title {
	font-size: 24px;
	font-weight: 400;
}

.line-box {
	display: flex !important;
}
.savings-info {
	font-weight: 400;
	font-size: 18px;
	margin-top: 5%;
}

.chart-info {
	width: 85%;
}
.img-fluid {
	width: 100%;
}
.unClick {
	pointer-events: none !important;
}

 #ev-jumbotron-title, #charging-map, #incentives, #EVs, #home-charging-advisor, #events {
	background-color: $background_color_8 !important;
}
