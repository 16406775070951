@import '../../scss/client_master.scss';

#faqContainer{
    .dark-gray {
        background-color: #797D83;
        color: white;
    }
    .light-gray{
        background-color: #C1C4C8;
    }
    .expanded-rebate-table {
        width: 100%;
    }
}