@import "src/client_customizations/scss/client_master.scss";

.inventory-filter-controls {
    .ev-condition-button {
		background-color: $Inventory-condition-background;
		border: $Inventory-condition-border;
		border-radius: $Inventory-condition-border-radius;
        padding: $Inventory-condition-padding;
        border-radius: 0px;
        text-transform: uppercase;
    }
    
	.ev-condition-button.active {
        background-color: $Inventory-condition-background-active;
        color: $Inventory-filter-active-font-color;
	}

    .dealer-card {
        background-color: $Inventory-dealer-card-background;
        border: $Inventory-dealer-card-border;
        border-radius: $Inventory-dealer-card-border-radius;
        min-height: 60px;
    }
    .dealer-card.active {
        background-color: $Inventory-dealer-card-background-active;
        color: $Inventory-filter-active-font-color;
    }
    .dealer-card:not(:last-child) {
        margin-bottom: 1rem;
    }
    .dealer-grid-container {
		display: flex;
		flex-direction: column;
		button {
			border: 1px solid #DFDFDF ;
			margin-bottom: 8px;
			background-color: $Inventory-condition-background;
			&:hover {
                background-color: $Inventory-dealer-card-background-active;
                color: $Inventory-filter-active-font-color;
			}
        }
        img {
            background-color: $Inventory-dealer-image-background;
            border-radius: $Inventory-dealer-card-border-radius;
        }
	}

    .arrow-right-wrapper {
        color: #005F83;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 5px;
        &:after {
            content: " ";
            background-image: url("../../client_customizations/assets/images/icons/right-arrow.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 12px;
            height: 12px;
            margin-left: 8px;
        }
    }

    button, select, input {
        border-radius: 0;
    }

    label {
        margin-bottom: 5px;
    }

    .accordion-header.expanded ~ .accordion-body {
        display: block !important;
    }

    .accordion-header.expanded {
        .expand-collapse-icon {
            background-image: url("../../client_customizations/assets/images/icons/minus.svg");
        }
    }
    .accordion-body {
        margin-top: 10px;
        display: none;
    }

    .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .h2 {
            margin-bottom: 0;
        }
        .expand-collapse-icon {
            cursor: pointer;
            width: 20px;
            height: 20px;
            padding: 8px;
            background-image: url("../../client_customizations/assets/images/icons/plus.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }
    }

    .button-group {
        button {
            width: 100%;
            &:first-child {
                border-right: none;
            }

            &:last-child {
                border-left: none;
            }

            & .selected, .active {
                background: #005F83;
            }
            padding-left: 2px;
            padding-right: 2px;
        }
    }

    .form-control {
        font-size: .8rem;
    }
}