@import "src/client_customizations/scss/client_master.scss";

.inventory {
    .no-matches {
        padding: $Inventory-no-matches-padding;
        background-color: $Inventory-no-matches-background;
    }
    .available-nearby {
        font-weight: $Inventory-available-nearby-font-weight;
        padding: $Inventory-available-nearby-padding;
    }

    .btn-ae {
        padding: 10px;
    }
} 
