.SelectedChargingStationCard {
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  padding: 15px;
  font-size: 0.75rem;

  .map-marker {
    margin: 35px 0px 50px 5px;
  }
}
