.MapLegend:empty {
  display: none;
}

.MapLegend {
  background-color: #000000;
  padding: 0.5em;
  position: absolute;
  z-index: 1;
  font-size: 0.75em;
  color: #ffffff;
  bottom: 0;
  left: 0
}