@import "src/client_customizations/scss/client_master.scss"; 

.CompareVehicles {
		background-color: #f4f4f4;
		padding-top:40px;

		.compared-vehicle-container {
			background-color: #f4f4f4 !important;
			padding-top: 20px;
		}

		.single-vehicle {
			padding-top: 50px;
			padding-bottom: 50px;
		}
}