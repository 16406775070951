@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}
.homepage-jumbotron {
	.big-promise {
		display: flex;
		flex-direction: column;
		text-align: left;
		justify-content: center;
		padding: $HomePage-big-promise-padding;
		background-color: $HomePage-big-promise-background-color;
		color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 50%;
		
	.homepage-header {
		font-family: "Gotham Bold";
		font-size: 56px;
	}
	.homepage-subheader {
		font-family: "Gotham Book";
		font-size: 24px;
		font-weight: 400;
	}
}
@media (max-width: 576px) {
			.big-promise {
				padding: 20px 0px !important;
				justify-content: flex-end;
				text-align: center;
				margin-left: auto;
				margin-right: auto;
				.homepage-header {
						font-family: "Gotham Bold";
						font-size: 24px;
						}
					.homepage-subheader {
						font-family: "Gotham Book";
						font-size: 10px;
						font-weight: 400;
						}
			}
				}
}
#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}
#HomepageVehiclesCarousel {
	background-color: #F3F3F3;
}
#HomepageIncentives {
	background-color: white;
}