.ChargingStationsMapLegend {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 15px;
  font-size: 0.75rem;

  .charger-type {
    position: relative;
    padding-right: 2rem;
    margin-bottom: 1rem;
  }

  .charger-type:last-child {
    margin-bottom: 0;
  }

  .charger-type img {
    width: 25px;
    position: absolute;
    right: 0;
    top: 0;
  }
}
