.EVReviews {
  text-align: center;
  padding: 0;
}

.EVReviews .h3 {
  text-align: left;
}

.EVReviews p {
  margin-top: 10px;
}

.VideoReview {
  margin-top: 10px;
  margin-bottom: 30px;
}
